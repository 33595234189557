import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle';
import './assets/main.css';

import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App);
app.provide('bootstrap', bootstrap);

app.config.globalProperties.$isDev = process.env.NODE_ENV == "development";
app.config.globalProperties.$isLocalhost = window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1' ||
    window.location.hostname === '::1';

app.mount('#app');
