<template>
  <img alt="Ax logo" src="./assets/DarkOrangeLogoBlack.svg" width="300px">
  <LandingPage />
</template>

<script>
import LandingPage from "@/components/Landing.vue";

export default {
  name: 'App',
  components: {
    LandingPage
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
